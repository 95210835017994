import React, {  useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { connect } from "react-redux";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";
import { finishEvent, deleteEvent } from "../../api/order";
import action from "../../store/action";

import { Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import EventEditForm from "./Form/EventEditForm";
import EventCreateForm from "./Form/EventCreateForm";

const useStyles = makeStyles(theme => ({
  paper: {
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid transparent",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: "auto",
    marginTop: "25vh"
  }
}));

const localizer = momentLocalizer(moment);

function EventComponent(event) {
  const {
    event: {
      resource: { finished }
    }
  } = event;
  const style = finished
    ? {
        color: "lightsalmon"
      }
    : {
        color: "black"
      };
  return <div style={style}>{event.title}</div>;
}

const MyCalendar = props => {
  const {
    events,
    finish_event,
    create_event,
    global_action_loading,
    services,
    delete_event
  } = props;

  const minTime = new Date();
  minTime.setHours(8, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(19, 0, 0);

  const [detailInfo, setDetailInfo] = useState("");
  const [creatingEvent, setCreatingEvent] = useState(null);
  //handle edit modal
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleEditCloseModal = () => {
    setOpenEditModal(false);
  };
  const handleEdit = event => {
    setDetailInfo(event);
    setOpenEditModal(true);
  };
  //handle create modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const handleCreateOpen = () => {
    setOpenCreateModal(true);
  };
  const handleCreateCloseModal = () => {
    setOpenCreateModal(false);
  };
  const handleCreate = ({ start, end }) => {
    setCreatingEvent({ start, end });
    handleCreateOpen();
  };

  const handleFinishEvent = () => {
    if (window.confirm(`Do you want to Finish ${detailInfo.title}?`)) {
      global_action_loading();
      finishEvent({ id: detailInfo.id, isDetail: detailInfo.resource.isDetail })
        .then(res => {
          if (res.data.status === 200) {
            finish_event(detailInfo.id);
          }
          global_action_loading(false);
        })
        .catch(err => {
          global_action_loading(false);
        });
    }
    handleEditCloseModal();
  };

  const handleDeleteEvent = () => {
    if (window.confirm(`Do you want to Delete ${detailInfo.title}?`)) {
      global_action_loading();
      deleteEvent({ id: detailInfo.id })
        .then(res => {
          if (res.data.status === 200) {
            delete_event(detailInfo.id);
          }
          global_action_loading(false);
        })
        .catch(err => {
          global_action_loading(false);
        });
    }
    handleEditCloseModal();
  };

  const classes = useStyles();
  return (
    <div>
      <div>
        <Typography variant="body2">
          <span
            style={{
              border: "1px solid rgba(0, 172, 193, 0.8)",
              padding: "5px",

              color: "lightsalmon"
            }}
          >
            Finished
          </span>
          <span
            style={{
              border: "1px solid rgba(0, 172, 193, 0.8)",
              padding: "5px",

              color: "black"
            }}
          >
            Unfinished
          </span>
        </Typography>
      </div>
      <br />
      <Calendar
        localizer={localizer}
        events={events}
        style={{
          height: "80vh",
          background: "white",
          padding: "2%"
        }}
        min={minTime}
        max={maxTime}
        defaultDate={
          new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0))
        }
        selectable
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={e => {
          handleEdit(e);
        }}
        onSelectSlot={handleCreate}
        popup={true}
        views={["month", "week", "agenda", "day"]}
        defaultView="week"
        length={7}
        step={15}
        components={{
          event: EventComponent
        }}
        hideGutter
      ></Calendar>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openCreateModal}
        onClose={handleCreateCloseModal}
      >
        <div className={classes.paper}>
          <EventCreateForm
            services={services}
            creatingEvent={creatingEvent}
            create_event={create_event}
            global_action_loading={global_action_loading}
            handleCreateCloseModal={handleCreateCloseModal}
          />
        </div>
      </Modal>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openEditModal}
        onClose={handleEditCloseModal}
      >
        <div className={classes.paper}>
          <EventEditForm
            closeEdit={handleEditCloseModal}
            detailInfo={detailInfo}
            handleDeleteEvent={handleDeleteEvent}
            handleFinishEvent={handleFinishEvent}
          />
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(
  connect(
    state => {
      return {
        ...state.order,
        ...state.service
      };
    },
    {
      ...action.order,
      ...action.globalLoading
    }
  )(MyCalendar)
);
