/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
// import Dashboard from '@material-ui/icons/Dashboard';
// import Person from '@material-ui/icons/Person';
import CreateIcon from '@material-ui/icons/Create';
import ViewListIcon from '@material-ui/icons/ViewList';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TocIcon from '@material-ui/icons/Toc';

// core components/views for Admin layout
// import DashboardPage from 'views/Dashboard/Dashboard.js';

import ServiceList from 'views/ServiceList/index';

import Order from 'views/Order';
import Calendar from 'views/Calendar/Calendar.js';
import Create from 'views/Create/index.js';

const dashboardRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: Dashboard,
  //   component: DashboardPage
  // },

  {
    path: '/order',
    name: 'Order',
    icon: TocIcon,
    component: Order
  },
  {
    path: '/calendar',
    name: 'Calendar',
    icon: CalendarTodayIcon,
    component: Calendar
  },
  {
    path: '/service',
    name: 'Service List',
    icon: ViewListIcon,
    component: ServiceList
  },

  {
    path: '/create',
    name: 'Create',
    icon: CreateIcon,
    component: Create
  }
];

export default dashboardRoutes;
