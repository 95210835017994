export const GET_SERVICES = "GET_SERVICES";
export const GLOBAL_LOADING = "GLOBAL_LOADING";
export const ADD_SERVICE = "ADD_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const AUTH = "AUTH";
export const GET_EVENTS = "GET_ORDERS";
export const CREATE_EVENT = "CREATE_EVENT";
export const FINISH_EVENT = "FINISH_EVENT";
export const SET_ORDERS = "SET_ORDERS";
export const SET_SEARCH_ORDERS = "SET_SEARCH_ORDERS";
export const CANCEL_SEARCHING = "CANCEL_SEARCHING";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const CHANGE_EVENT = "CHANGE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
