import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import action from '../../store/action';
import { getOrders } from '../../api/order';
import OneOrder from './OneOrder';
import Button from '@material-ui/core/Button';

const limit = 8;

function OrderList(props) {
  const {
    global_action_loading,
    orders,
    set_orders,
    searching,
    searchingOrders
  } = props;
  const [getInfo, setGetInfo] = useState({
    offset: 0,
    noMore: false
  });
  const { offset, noMore } = getInfo;

  const getTheOrders = callback => {
    if (noMore) return;
    global_action_loading();
    getOrders({ offset, limit })
      .then(res => {
        if (res.data.status === 200) {
          const data = res.data.data;
          setGetInfo({
            offset: offset + limit,
            noMore: data.length < limit
          });
          callback(data);
        } else {
        }
        global_action_loading(false);
      })
      .catch(err => {
        global_action_loading(false);
      });
  };

  const getMore = () => {
    getTheOrders(data => {
      set_orders([...orders, ...data]);
    });
  };

  useEffect(() => {
    if (orders.length >= limit) {
      setGetInfo({ ...getInfo, offset: orders.length });
    } else {
      getTheOrders(data => {
        set_orders(data);
      });
    }
  }, []);

  return (
    <React.Fragment>
      {searching ? (
        <div style={{ width: '100%' }}>
          {searchingOrders.length > 0 &&
            searchingOrders.map(i => <OneOrder order={i} key={i.id} />)}
        </div>
      ) : (
        <React.Fragment>
          {' '}
          <div style={{ width: '100%' }}>
            {orders.length > 0 &&
              orders.map(i => <OneOrder order={i} key={i.id} />)}
          </div>
          <Button
            onClick={() => {
              getMore();
            }}
            disabled={noMore}
          >
            Get More Order
          </Button>{' '}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default connect(
  state => state.order,
  { ...action.order, ...action.globalLoading }
)(OrderList);
