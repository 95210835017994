import * as TYPES from '../action-type';
import { getInfo } from '../../utils/auth';
export default function personReducer(
  state = {
    auth: getInfo() ? true : false
  },
  action
) {
  switch (action.type) {
    case TYPES.AUTH:
      state = { ...state };
      state.auth = action.data;
      break;
    default:
      break;
  }
  return state;
}
