import { useState,useEffect } from 'react';

const useFormInput =(init,count) => {
  const [value, setValue] = useState(init);
  useEffect(()=>{setValue(init)},[count,init])
  const onChange = e => {
   setValue(e.target.value);
  };
 
  return { value, onChange };
};

export default useFormInput;