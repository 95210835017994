import * as TYPES from "../action-type";
import { getEvents } from "../../api/order";

const order = {
  get_orders() {
    return async dispatch => {
      let Data = await getEvents();
      dispatch({
        type: TYPES.GET_EVENTS,
        Data
      });
    };
  },
  create_event(event) {
    return {
      type: TYPES.CREATE_EVENT,
      event
    };
  },
  finish_event(id) {
    return {
      type: TYPES.FINISH_EVENT,
      id
    };
  },
  set_orders(orders) {
    return {
      type: TYPES.SET_ORDERS,
      orders
    };
  },
  set_search_orders(orders) {
    return {
      type: TYPES.SET_SEARCH_ORDERS,
      orders
    };
  },
  cancel_searching() {
    return {
      type: TYPES.CANCEL_SEARCHING
    };
  },
  change_event(event) {
    return {
      type: TYPES.CHANGE_EVENT,
      event
    };
  },
  delete_event(id) {
    return {
      type: TYPES.DELETE_EVENT,
      id
    };
  }
};

export default order;
