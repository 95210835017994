import React, {useState} from 'react';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import 'date-fns';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {connect} from 'react-redux';
import action from '../../store/action';
import {searchOrders} from '../../api/order';
import {TextField} from '@material-ui/core';
import useFormInput from '../../hooks/useFormInput';

const today = new Date();
today.setHours(23, 59, 59);
const yestoday = new Date(today.getTime() - 86400000);
yestoday.setHours(0, 0, 0);

const returnIdSearch = (id, call1, call2) => {
    return id
        ? call1
        : call2;
};

function OrderSearch(props) {
    const {global_action_loading, cancel_searching, set_search_orders, searching} = props;
    const [timeFrom,
        setTimeFrom] = useState(yestoday);
    const id = useFormInput('');
    const handleFromChange = date => {
        date.setHours(0, 0, 0);
        setTimeFrom(date);
    };

    const [timeTo,
        setTimeTo] = useState(today);

    const handleToChange = date => {
        date.setHours(23, 59, 59);
        setTimeTo(date);
    };

    const hanleSearch = id => {
        global_action_loading();
        returnIdSearch(id, searchOrders({id}), searchOrders({timeFrom, timeTo})).then(res => {
            if (res.data.status === 200) {
                set_search_orders(res.data.data);
            }
            global_action_loading(false);
        }).catch(err => {
            global_action_loading(false);
        });
    };

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid container item xs={6} md={6} spacing={2}>
                    <div>
                        <div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    margin='normal'
                                    id='date-picker-dialog'
                                    label='Choose from date'
                                    format='MM/dd/yyyy'
                                    value={new Date(timeFrom)}
                                    onChange={handleFromChange}/>
                                <DatePicker
                                    margin='normal'
                                    id='date-picker-dialog'
                                    label='Choose to date'
                                    format='MM/dd/yyyy'
                                    value={new Date(timeTo)}
                                    onChange={handleToChange}/>
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div
                        style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div>
                            <Button
                                variant='outlined'
                                color='default'
                                onClick={() => {
                                hanleSearch(false);
                            }}>
                                Search by date
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid container item xs={6} md={6} spacing={2}>
                    <div>
                        <TextField required label='ID' {...id} margin='normal'/></div>
                    <div
                        style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div>
                            <Button
                                variant='outlined'
                                color='default'
                                onClick={() => {
                                hanleSearch(id.value);
                            }}>
                                Search by Id
                            </Button>

                            {searching && (
                                <Button
                                    variant='outlined'
                                    color='secondary'
                                    onClick={() => {
                                    cancel_searching();
                                }}>
                                    Cancel Searching
                                </Button>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default connect(null, {
    ...action.order,
    ...action.globalLoading
})(OrderSearch);
