import * as TYPES from '../action-type';
import { diffTypes } from '../../utils/utils';

export default function service(
  state = {
    services: {
      cloth: [],
      photo: [],
      product: []
    }
  },
  action
) {
  switch (action.type) {
    case TYPES.GET_SERVICES:
      {
        const { status, data } = action.Data.data;
        if (status === 200) {
          state = JSON.parse(JSON.stringify(state));
          state.services = diffTypes(data);
        }
      }
      break;
    case TYPES.ADD_SERVICE:
      {
        const { type } = action.data;
        state = JSON.parse(JSON.stringify(state));
        state.services[type].push(action.data);
      }
      break;
    case TYPES.DELETE_SERVICE:
      {
        const { type } = action.data;
        state = JSON.parse(JSON.stringify(state));
        state.services[type] = state.services[type].filter(
          item => item.id !== action.data.id
        );
      }
      break;
    case TYPES.UPDATE_SERVICE:
      {
        const { type } = action.data;
        state = JSON.parse(JSON.stringify(state));
        state.services[type] = state.services[type].map(item =>
          item.id !== action.data.id ? item : { ...item, ...action.data }
        );
      }
      break;
    default:
      break;
  }
  return state;
}
