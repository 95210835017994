import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { setInfo } from '../../utils/auth'
import useFormInput from '../../hooks/useFormInput'

import { login } from '../../api/admin'
import { connect } from 'react-redux'
import action from '../../store/action'
const styles = {
    card:{
        marginTop:'15vh'
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
};

const useStyles = makeStyles(styles);

function Login(props) {
    const classes = useStyles();

    const username = useFormInput('')
    const password = useFormInput('')

    const handleLogin = () => {
        props.global_action_loading();
        login({ username: username.value, password: password.value }).then(res => {
            setInfo(username.value)
            props.change_auth(true)
            props.global_action_loading(false);

        })
    }

    return (<GridContainer>
        <GridItem xs={10} sm={4} md={3}>
            <Card className={classes.card}>
                <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Login</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                labelText="Username"
                                inputProps={{ ...username }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput                     
                                labelText="Password"
                                inputProps={{ ...password }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
                <CardFooter>
                    <Button onClick={handleLogin} color="primary">Login</Button>
                </CardFooter>
            </Card>
        </GridItem>
    </GridContainer>
    );
}
export default connect(null, { ...action.person, ...action.globalLoading })(Login)