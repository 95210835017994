import * as TYPES from '../action-type';
import { getServices } from '../../api/service';

const service = {
  get_services(props) {
    return async dispatch => {
      let Data = await getServices(props);
      dispatch({
        type: TYPES.GET_SERVICES,
        Data
      });
    };
  },
  add_service(data) {
    return {
      type: TYPES.ADD_SERVICE,
      data
    };
  },
  delete_service(data) {
    return {
      type: TYPES.DELETE_SERVICE,
      data
    };
  },
  update_service(data) {
    return {
      type: TYPES.UPDATE_SERVICE,
      data
    };
  }
};

export default service;
