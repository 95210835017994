export function diffTypes(services) {
  return services.reduce((obj, service) => {
    const { type } = service;
    obj[type] = obj[type] ? [...obj[type], service] : [service];
    return obj;
  }, {});
}

export function findService(services, type, id) {
  const index = services[type].findIndex(i => i.id === Number(id));
  return index === -1 ? {} : services[type][index];
}

export function orderToEvents(orders, events) {
  return orders.reduce(
    (eventArray, order) => {
      return eventArray.concat(
        order.details.map(detail => ({
          id: detail.id,
          title: detail.title,
          start: new Date(detail.start),
          end: new Date(detail.end),
          resource: {
            isDetail: true,
            finished: detail.finished,
            lastname: order.lastname,
            firstname: order.firstname,
            phone: order.phone,
            email: order.email
          }
        }))
      );
    },
    events.map(event => ({
      id: event.id,
      title: event.title,
      start: new Date(event.start),
      end: new Date(event.end),
      resource: {
        isDetail: false,
        finished: event.finished,
        ...event.resource
      }
    }))
  );
}
