import React, { useState, useRef } from 'react';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

import { connect } from 'react-redux';
import ExpansionTable from './ExpansionTable';
import Detail from './Details';
import { findService } from '../../utils/utils';
const clothInfo = {
  Description: 'description',
  School:'school',
  Degress:'degree',
  Major:'major',
  Price: 'price',
  Stock: 'stock',
  Actions: 'id'
};

const productInfo = {
  Image: 'pic_url',
  Name: 'name',
  Description: 'description',
  Price: 'price',
  Stock: 'stock',
  Actions: 'id'
};

const photoInfo = {
  Type: 'pack',
  Name: 'name',
  Description: 'description',
  Price: 'price',
  Actions: 'id'
};

function ServiceList(props) {
  const myRef = useRef(null);
  const { services } = props;
  const [height, setHeight] = useState(0);
  const { cloth, photo, product } = services;
  const [detailDisplay, setDetailDisplay] = useState(false);
  const [service, setService] = useState({});
  const goToDetail = (id, type) => {
    setService(findService(services, type, id));
    setDetailDisplay(true);
    setHeight(myRef.current.scrollTop);
  };

  const scrollToCurrent = y => myRef.current.scroll({ top: y, left: 0 });

  const goBack = () => {
    setDetailDisplay(false);
    setTimeout(() => {
      scrollToCurrent(height);
    }, 0);
  };

  return (
    <React.Fragment>
      <div
        ref={myRef}
        style={{
          height: '85vh',
          overflowY: 'auto'
        }}
      >
        <GridContainer
          style={{
            display: detailDisplay ? 'none' : 'block'
          }}
        >
          <GridItem xs={12} sm={12} md={12}>
            <ExpansionTable
              title={'Regalia Table'}
              tableHead={clothInfo}
              tableData={cloth}
              tableHeaderColor='primary'
              onClick={goToDetail}
            />
            <ExpansionTable
              title={'Product Table'}
              tableHead={productInfo}
              tableData={product}
              tableHeaderColor='primary'
              onClick={goToDetail}
            />
            <ExpansionTable
              title={'Photography Table'}
              tableHead={photoInfo}
              tableData={photo}
              tableHeaderColor='primary'
              onClick={goToDetail}
            />
          </GridItem>
        </GridContainer>{' '}
        {detailDisplay ? (
          <Detail goBack={goBack} service={service} />
        ) : (
          <div></div>
        )}
      </div>
    </React.Fragment>
  );
}

export default connect(state => state.service)(ServiceList);
