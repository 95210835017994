import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import FaceIcon from "@material-ui/icons/Face";
import SendIcon from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { Typography, Button } from "@material-ui/core";
import { changeDetail } from "../../../api/order";
import action from "../../../store/action";
import { connect } from "react-redux";

const styles = {
  root: {
    display: "flex",
    justfyContent: "center",
    alignItems: "center"
  },
  btn: {
    margin: "4%"
  },
  textField: {}
};
const useStyles = makeStyles(styles);

function EventEditForm(props) {
  const {
    detailInfo,
    handleFinishEvent,
    change_event,
    closeEdit,
    global_action_loading,
    handleDeleteEvent
  } = props;

  const classes = useStyles();
  const startDate = moment(detailInfo.start).format("YYYY-MM-DD");
  const startTime = moment(detailInfo.start).format("hh:mm");

  const [updateDate, setUpdateDate] = useState(startDate);
  const [updateTime, setUpdateTime] = useState(startTime);

  const lastname = detailInfo.resource.lastname;
  const firstname = detailInfo.resource.firstname;
  const email = detailInfo.resource.email;
  const phone = detailInfo.resource.phone;

  const handleSendInfo = () => {};

  const handleEditEvent = () => {
    global_action_loading();
    const start = moment(updateDate + " " + updateTime).valueOf();
    const data = {
      id: detailInfo.id,
      isDetail: detailInfo.resource.isDetail,
      start
    };

    changeDetail(data)
      .then(res => {
        if (res.data.status === 200) {
          const event = {
            ...detailInfo,
            start: new Date(start),
            end: new Date(res.data.end)
          };
          console.log(event);
          change_event(event);
          closeEdit();
        } else {
          alert("failed");
        }
        global_action_loading(false);
      })
      .catch(err => {
        console.log(err);
        alert("err");
        global_action_loading(false);
      });
  };

  return (
    <div>
      <div>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FaceIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={firstname + " " + lastname}
              secondary={"Phone: " + phone}
            />
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body2">
                Email : {email}
                <Button onClick={handleSendInfo}>
                  <SendIcon />
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Info : {detailInfo.title}</Typography>
          </ListItem>
          <ListItem>
            <TextField
              id="date"
              label="Service Date"
              type="date"
              onChange={e => setUpdateDate(e.target.value)}
              defaultValue={startDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="time"
              label="Time"
              type="time"
              onChange={e => setUpdateTime(e.target.value)}
              defaultValue={startTime}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 900
              }}
            />
          </ListItem>

          <ListItem>
            {!detailInfo.resource.finished ? (
              <div className={classes.root}>
                <Button
                  className={classes.btn}
                  variant="outlined"
                  color="default"
                  onClick={handleEditEvent}
                >
                  Update
                </Button>
                <Button
                  className={classes.btn}
                  variant="outlined"
                  color="primary"
                  onClick={handleFinishEvent}
                >
                  Finish
                </Button>{" "}
              </div>
            ) : (
              <ListItemText>You've already finished this event</ListItemText>
            )}
            {!detailInfo.resource.isDetail && (
              <Button
                className={classes.btn}
                variant="outlined"
                color="secondary"
                onClick={handleDeleteEvent}
              >
                Delete
              </Button>
            )}
          </ListItem>
        </List>
      </div>
    </div>
  );
}

export default connect(
  null,
  { ...action.globalLoading, ...action.order }
)(EventEditForm);
