import { _get, _post, _put, _delete } from "./index";

export const getEvents = data => {
  let req = {
    data,
    url: "admin/get_events"
  };
  return _get(req);
};

export const createEvent = data => {
  let req = {
    data,
    url: "admin/create_event"
  };
  return _post(req);
};

export const deleteEvent = data => {
  let req = {
    url: `admin/delete_event/${data.id}`
  };
  return _delete(req);
};

export const finishEvent = data => {
  let req = {
    data,
    url: `admin/finish_event/${data.id}`
  };
  return _post(req);
};

export const changeDetail = data => {
  let req = {
    data,
    url: `admin/change_detail/${data.id}`
  };
  return _put(req);
};

export const getOrders = data => {
  let req = {
    data,
    url: "admin/get_orders"
  };
  return _get(req);
};

export const searchOrders = data => {
  let req = {
    data,
    url: "admin/search_orders"
  };
  return _get(req);
};
