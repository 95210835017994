import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    card: {
        padding: '1% 2%',
        margin: '1%'
    },
    title: {
        fontSize: 14
    }
});

export default function OneOrder({order}) {
    console.log(order);
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <Grid container spacing={1}>
                <Grid container item xs={4} md={4} spacing={1}>
                    <CardContent>
                        <Typography className={classes.title} color='textSecondary' gutterBottom>
                            OrderID : {order.id}
                        </Typography>
                        <Typography variant='subtitle1' gutterBottom>
                            Customer: {order.firstname}
                            {order.lastname}
                        </Typography>
                        <Typography className={classes.title} color='textSecondary'>
                            Contact: {order.phone}
                        </Typography>
                        <Typography className={classes.title} color='textSecondary'>
                            Email: {order.email}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid container item xs={8} md={8} spacing={1}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="left">Info</TableCell>
                                <TableCell align="left">Service Date</TableCell>                           
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order
                                .details
                                .map(i => (
                                    <TableRow key={i.id}>
                                        <TableCell align="left">{i.type}</TableCell>
                                        <TableCell align="left">{i.title}</TableCell>
                                        <TableCell align="left">
                                            {i.start && new Date(i.start).toLocaleDateString()}</TableCell>                                       
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Card>
    );
}
