import React from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// core components
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import {connect} from 'react-redux';

import {TextField, Grid} from '@material-ui/core/';
import useFormInput from '../../hooks/useFormInput';
import {updateService} from '../../api/service';
import action from '../../store/action';

const styles = {
    note: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        color: '#c0c1c2',
        display: 'flex',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '13px'
    },
    cardHead: {
        display: 'flex',
        flexDeriction: 'row',
        alignItems: 'center'
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
        marginLeft: '5px'
    },
    backButton: {
        fontSize: 32,
        height: 32,
        alignItems: 'center',
        lineHeight: 0
    },
    textField: {
        width: 600
    }
};

const useStyles = makeStyles(styles);

function Detail(props) {
    const {service, goBack, global_action_loading, update_service} = props;
    const {type} = service;
    const classes = useStyles();

    const price = useFormInput(service.price);
    const stock = useFormInput(service.stock);
    const name = useFormInput(service.name);
    const description = useFormInput(service.description);
    const updateTheService = () => {
        global_action_loading();
        const data = {
            id: service.id,
            price: price.value,
            stock: stock.value,
            description: description.value,
            type: service.type
        };
        updateService(data).then(res => {
            if (res.data.status === 200) {
                update_service(data);
                setTimeout(() => {
                    goBack();
                }, 10);
            }
            global_action_loading(false);
        }).catch(err => {
            global_action_loading(false);
        });
    };
    return (
        <Card>
            <CardHeader className={classes.cardHead} color='primary'>
                <Button
                    onClick={() => {
                    goBack();
                }}
                    color='transparent'
                    size='sm'
                    className={classes.backButton}>
                    {'< '}{' '}
                    <span
                        style={{
                        fontSize: 14,
                        marginLeft: 5,
                        opacity: 0.5
                    }}>
                        {' '}
                        Back
                    </span>
                </Button>
                <h4 className={classes.cardTitleWhite}>Service Detail</h4>
            </CardHeader>
            <CardBody>
                <Grid
                    container
                    spacing={1}
                    style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                    <Grid container item xs={8} md={8} spacing={1}>
                        <div className={classes.note}>
                            <div>id: {service.id}</div>
                        </div>
                        {/* <Typography variant='body1'>
                            Name: {service.name} ,
                            </Typography><br/>
                            <Typography variant='body2'>
                            Description: {service.description}
                            </Typography><br/>
                            <Typography variant='body2'>
                            Price: $NZD
                            {service.price}
                            </Typography> */}
                        <div><TextField
                            disabled
                            label='Name'
                            variant='outlined'
                            className={classes.textField}
                            {...name}
                            margin='normal'/>
                            <TextField
                                required
                                multiline
                                label='Description'
                                variant='outlined'
                                className={classes.textField}
                                {...description}
                                margin='normal'/>
                            <TextField
                                required
                                label='Price'
                                variant='outlined'
                                className={classes.textField}
                                {...price}
                                margin='normal'/> {type !== 'photo' && (<TextField
                                required
                                label='Stock'
                                variant='outlined'
                                className={classes.textField}
                                {...stock}
                                margin='normal'/>)}</div>
                        <div></div>
                    </Grid>
                </Grid>
                <div
                    style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div>
                        <Button variant='contained' color='primary' onClick={updateTheService}>
                            Submit
                        </Button>
                    </div>
                </div>

            </CardBody>
        </Card>
    );
}

export default connect(state => state.service, {
    ...action.globalLoading,
    ...action.service
})(Detail);
