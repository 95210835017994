import { _get, _post, _delete, _put } from './index';

export const getServices = data => {
  let req = {
    data,
    url: 'user/services'
  };
  return _get(req);
};

export const createService = data => {
  let req = {
    data,
    url: 'admin/service'
  };
  return _post(req);
};

export const updateService = data => {
  let req = {
    data,
    url: `admin/service/${data.id}`
  };
  return _put(req);
};

export const deleteService = data => {
  let req = {
    url: `admin/service/${data.id}`
  };
  return _delete(req);
};
