import React from 'react';
import OrderList from './OrderList';
import OrderSearch from './OrderSearch';
import action from '../../store/action';
import { connect } from 'react-redux';

function Order(props) {
  const { searching } = props;

  return (
    <React.Fragment>
      <OrderSearch searching={searching} />
      <br/>
      <OrderList searching={searching} />
    </React.Fragment>
  );
}

export default connect(
  state => state.order,
  action.order
)(Order);
