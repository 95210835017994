import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {MenuItem, TextField, Button, Typography} from "@material-ui/core/";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";


import useFormInput from "../../hooks/useFormInput";
import {createService} from "../../api/service";
import {upload} from "../../api/upload";
import config from "../../config";
import action from "../../store/action";
import {connect} from "react-redux";
const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%'
    },
    invisable: {
        display: "none"
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    },
    button: {
        margin: theme.spacing(1)
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cardbody: {
        display: 'flex',
        justfyContent: 'center',
        alignItems: 'center'
    }
}));

const types = [
    {
        value: "cloth",
        label: "Regalia"
    }, {
        value: "product",
        label: "Product"
    }, {
        value: "photo",
        label: "Photography"
    }
];
const schools = [
    {
        value: "AUT",
        label: "AUT"
    }, {
        value: "UOA",
        label: "UNIVERSITY OF AUCKLAND"
    }, {
        value: "MASSY",
        label: "MASSEY UNIVERSITY"
    }
];

const degrees = [
    {
        value: "bachelor",
        label: "Bachelor Degree"
    }, {
        value: "gd&pgd",
        label: "Graduate Diploma & Post Graduate Diploma"
    }, {
        value: "master",
        label: "Master"
    }
];

const sizes = [
    {
        value: "s",
        label: "Small"
    }, {
        value: "m",
        label: "Medium"
    }, {
        value: "l",
        label: "Large"
    }
];

const packages = [
    {
        value: "wedding",
        label: "Wedding"
    }, {
        value: "portrait",
        label: "Portrait"
    }, {
        value: "realestate",
        label: "Real Estate"
    }, {
        value: "graduation",
        label: "Graduation"
    }, {
        value: "more",
        label: "More Photography"
    }
];

function Create(props) {
    const {global_action_loading, add_service} = props;

    const classes = useStyles();
    const [count,
        setCount] = useState(0);
    const type = useFormInput("cloth");
    const school = useFormInput("AUT");
    const degree = useFormInput("bachelor"); 
    const size = useFormInput("s");
    const pack = useFormInput("graduation");

    const name = useFormInput("",count);
    const description = useFormInput("",count);
    const price = useFormInput("",count);
    const major = useFormInput("",count);
    const stock = useFormInput("",count);
 
    const [file,
        setFile] = useState();

    function handlePicChange(ev) {
        if (!ev.target.files[0]) {
            return;
        }
        let file = ev.target.files[0];
        if (file.type.indexOf("image") < 0) {
            return;
        }
        if (window.Blob) {
            const fileName = file.name;
            const reader = new FileReader();
            reader.onload = e => {
                const img = document.createElement("img");
                img.src = e.target.result;
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    const MAX_WIDTH = 250;
                    const MAX_HEIGHT = 250;
                    let width = img.width;
                    let height = img.height;
                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);
                    ctx.canvas
                        .toBlob(blob => {
                            file = new File([blob], fileName, {
                                type: "image/jpeg",
                                lastModified: Date.now()
                            });
                            file.url = img.src;
                            setFile(file);
                        }, "image/jpeg", 1);
                };
            };
            reader.readAsDataURL(file);
        } else if (file.size > 1024 * 1024 * 3) {
            setFile("");
        } else {
            setFile(file);
        }
    }

    const addService = data => {
        createService(data).then(res => {
            if (res.data.status === 200) {
                data.id = res.data.data;
                add_service(data);
                setCount(count+1);
                setFile()
            }
            alert(res.data.message);
            global_action_loading(false);
        }).catch(er => {
            global_action_loading(false);
            alert("Error");
        });
    };

  

    const submitService = () => {
        global_action_loading();
        let data;
        switch (type.value) {
            case "cloth":
                data = {
                    type: type.value,
                    name: name.value,
                    description: description.value,
                    price: price.value,
                    school: school.value,
                    degree: degree.value,
                    major: major.value,
                    size: size.value,
                    stock: stock.value,
                    unique: `${name.value}-${school.value}-${major.value}-${degree.value}-${size.value}`
                };
                addService(data);
                return;
            case "product":
                data = {
                    type: type.value,
                    name: name.value,
                    description: description.value,
                    price: price.value,
                    stock: stock.value
                };
                break;
            case "photo":
                data = {
                    type: type.value,
                    name: name.value,
                    description: description.value,
                    price: price.value,
                    pack: pack.value
                };
                break;
            default:
                return;
        }
        if (file) {
            upload({file})
                .then(res => {
                const url = config.baseURL + "uploads/" + res.data.filename;
                return url;
            })
                .then(url => {
                    data.pic_url = url;
                    addService(data);
                })
                .catch(err => {
                    global_action_loading(false);
                });
        } else {
            addService(data);
        }
       
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Create services</h4>
                        <p className={classes.cardCategoryWhite}>Complete a new service</p>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.cardbody}>
                            <form className={classes.container} noValidate autoComplete="off">
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            select
                                            label="Type"
                                            className={classes.textField}
                                            {...type}
                                            SelectProps={{
                                            MenuProps: {
                                                className: classes.menu
                                            }
                                        }}
                                            helperText="Please select types"
                                            margin="normal">
                                            {types.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            select
                                            label="Package Type"
                                            className={type.value === "photo"
                                            ? classes.textField
                                            : classes.invisable}
                                            {...pack}
                                            SelectProps={{
                                            MenuProps: {
                                                className: classes.menu
                                            }
                                        }}
                                            helperText="Please select photography type"
                                            margin="normal">
                                            {packages.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            required
                                            label="Name"
                                            className={classes.textField}
                                            {...name}
                                            margin="normal"/>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={8}>
                                        <TextField
                                            id="standard-uncontrolled"
                                            label="Description"
                                            className={classes.textField}
                                            {...description}
                                            margin="normal"/></GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            select
                                            label="School"
                                            className={type.value === "cloth"
                                            ? classes.textField
                                            : classes.invisable}
                                            {...school}
                                            SelectProps={{
                                            MenuProps: {
                                                className: classes.menu
                                            }
                                        }}
                                            helperText="Please select a school"
                                            margin="normal">
                                            {schools.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            select
                                            label="Degree"
                                            className={type.value === "cloth"
                                            ? classes.textField
                                            : classes.invisable}
                                            {...degree}
                                            SelectProps={{
                                            MenuProps: {
                                                className: classes.menu
                                            }
                                        }}
                                            helperText="Please select a degree"
                                            margin="normal">
                                            {degrees.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            label="Major"
                                            helperText="Please type the major"
                                            className={type.value === "cloth"
                                            ? classes.textField
                                            : classes.invisable}
                                            {...major}
                                            margin="normal"/></GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            required
                                            label="Price"
                                            className={classes.textField}
                                            {...price}
                                            margin="normal"/>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            label="Stock"
                                            className={type.value === "photo"
                                            ? classes.invisable
                                            : classes.textField}
                                            {...stock}
                                            margin="normal"/>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            select
                                            label="Size"
                                            className={type.value === "cloth"
                                            ? classes.textField
                                            : classes.invisable}
                                            {...size}
                                            SelectProps={{
                                            MenuProps: {
                                                className: classes.menu
                                            }
                                        }}
                                            helperText="Please select a size"
                                            margin="normal">
                                            {sizes.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                    <Typography
                                        component="div"
                                        variant='body2'
                                        className={type.value === "cloth"
                                        ? classes.invisable
                                        : classes.textField}>Select a photo
                                        {file
                                            && (
                                                <img
                                                    style={{
                                                    width: 250,
                                                    height: "auto"
                                                }}
                                                    alt="ad-img"
                                                    src={file.url}></img>
                                            )
                                          }
                                    </Typography>
                                    <br/>
                                    <Typography
                                        component="div"
                                        className={type.value === "cloth"
                                        ? classes.invisable
                                        : classes.textField}>
                                        <input
                                            id="input-image-file"
                                            name="file"
                                            onChange={e => handlePicChange(e)}
                                            type="file"/>
                                    </Typography>
                                    </GridItem>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={submitService}>
                                        Submit
                                    </Button>
                                </GridContainer>
                            </form>
                        </div>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>

    );
}

export default connect(null, {
    ...action.globalLoading,
    ...action.service
})(Create);
