import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core/";
import { createEvent } from "../../../api/order";
import useFormInput from "../../../hooks/useFormInput";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "80%"
  },
  invisable: {
    display: "none"
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
}));
export default function EventCreateForm(props) {
  const classes = useStyles();
  const firstname = useFormInput("");
  const lastname = useFormInput("");
  const title = useFormInput("");
  const email = useFormInput("");
  const phone = useFormInput("");
  const {
    creatingEvent: { start, end },
    create_event,
    global_action_loading,
    handleCreateCloseModal
  } = props;

  const handleCreateEvent = () => {
    if (title.value) {
      const data = {
        title: title.value,
        resource: {
          email: email.value,
          phone: phone.value,
          firstname: firstname.value,
          lastname: lastname.value,
          isDetail: false
        }
      };
      global_action_loading();
      createEvent({
        start: start.getTime(),
        end: end.getTime(),
        ...data
      })
        .then(res => {
          if (res.data.status === 200) {
            create_event({
              id: res.data.event_id,
              start,
              end,
              ...data
            });
            handleCreateCloseModal();
          } else {
            alert("failed");
          }
          global_action_loading(false);
        })
        .catch(err => {
          alert("err");
          global_action_loading(false);
        });
    }
  };
  return (
    <div>
      <TextField
        required
        label="Title"
        className={classes.textField}
        {...title}
        margin="normal"
      />
      <TextField
        label="FirstName"
        className={classes.textField}
        {...firstname}
        margin="normal"
      />
      <TextField
        label="LastName"
        className={classes.textField}
        {...lastname}
        margin="normal"
      />
      <TextField
        label="Email"
        className={classes.textField}
        {...email}
        margin="normal"
      />
      <TextField
        label="Phone"
        className={classes.textField}
        {...phone}
        margin="normal"
      />
      <Button
        className={classes.btn}
        variant="outlined"
        color="secondary"
        onClick={handleCreateEvent}
      >
        Create
      </Button>
    </div>
  );
}
