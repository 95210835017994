import * as TYPES from '../action-type';

const personRedux={
    change_auth(bool=false) {
        return {
            type: TYPES.AUTH,
            data:bool
        }
    },
};

export default personRedux;