import { _post } from './index'

//获取产品
export const login = (data) => {
  let req = {
    data,
    url: 'admin/login'
  };
  return _post(req);
};

export const logout = () => {
  let req = {
    url: 'admin/logout'
  };
  return _post(req);
};