import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from 'components/CustomButtons/Button.js';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import PropTypes from 'prop-types';
// @material-ui/core components

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// core components
import styles from 'assets/jss/material-dashboard-react/components/tableStyle.js';
import { deleteService } from '../../api/service';
import { connect } from 'react-redux';
import action from '../../store/action';

const useStyles = makeStyles(styles);

const CustomTable = React.memo(props => {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    title,
    onClick,
    global_action_loading,
    delete_service
  } = props;

  const deleteOneService = data => {
    global_action_loading();
    deleteService(data)
      .then(res => {
        if (res.data.status === 200) {
          delete_service(data);
        }
        alert(res.data.message);
        global_action_loading(false);
      })
      .catch(err => {
        alert('Err');
        global_action_loading(false);
      });
  };

  return (
    <ExpansionPanel className={classes.panel}>
      <ExpansionPanelSummary
        aria-controls={title + '-content'}
        id={title + '-header'}
      >
        <CardHeader color='primary'>
          <h4 className={classes.cardTitleWhite}>{title}</h4>
        </CardHeader>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Card className={classes.tableResponsive}>
          <CardBody>
            <Table className={classes.table}>
              {tableHead !== undefined ? (
                <TableHead
                  className={classes[tableHeaderColor + 'TableHeader']}
                >
                  <TableRow className={classes.tableHeadRow}>
                    {Object.keys(tableHead).map((title, key) => {
                      return (
                        <TableCell
                          className={
                            classes.tableCell + ' ' + classes.tableHeadCell
                          }
                          key={key}
                        >
                          {title}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {tableData.map((item, key) => {
                  return (
                    <TableRow key={key} className={classes.tableBodyRow}>
                      {Object.values(tableHead).map((value, key) => {
                        if (value === 'id')
                          return (
                            <TableCell
                              className={
                                classes.tableCell + ' ' + classes.tableHeadCell
                              }
                              key={key}
                            >
                              <Button
                                onClick={() => {
                                  onClick(item[value], item.type);
                                }}
                                color='info'
                                size='sm'
                              >
                                {'Details'}
                              </Button>
                              <Button
                                style={{ marginLeft: 30 }}
                                onClick={() => {
                                  deleteOneService({
                                    id: item[value],
                                    type: item.type
                                  });
                                }}
                                color='danger'
                                size='sm'
                              >
                                {'Delete'}
                              </Button>
                            </TableCell>
                          );
                        if (value === 'pic_url')
                          return (
                            <TableCell
                              className={
                                classes.tableCell + ' ' + classes.tableHeadCell
                              }
                              key={key}
                            >
                              <img
                                src={item.pic_url}
                                alt='product'
                                style={{ width: '50px', height: 'auto' }}
                              />
                            </TableCell>
                          );
                        return (
                          <TableCell
                            className={
                              classes.tableCell + ' ' + classes.tableHeadCell
                            }
                            key={key}
                          >
                            {item[value]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardBody>
        </Card>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});

/* return (
         value === "id" ? <TableCell
           className={classes.tableCell + " " + classes.tableHeadCell}
           key={key}
         >
           <Button onClick={() => { onClick(item[value], item.type) }} color="info" size="sm">
             {"Details"}
           </Button>
           <Button style={{ marginLeft: 30 }} onClick={() => { deleteOneService({ id: item[value], type: item.type }) }} color="danger" size="sm" >
             {"Delete"}
           </Button>
         </TableCell> : <TableCell
           className={classes.tableCell + " " + classes.tableHeadCell}
           key={key}
         >
             {item[value]}
           </TableCell>
       ); */

CustomTable.defaultProps = {
  tableHeaderColor: 'gray'
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray'
  ]),
  tableHead: PropTypes.object,
  tableData: PropTypes.arrayOf(PropTypes.object)
};
export default connect(
  null,
  { ...action.globalLoading, ...action.service }
)(CustomTable);
