import React, { useEffect } from 'react';
import { Provider, connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './views/Login';
import GlobalLoading from './components/GlobalLoading';
import Admin from 'layouts/Admin.js';
import action from './store/action';
import 'assets/css/material-dashboard-react.css?v=1.8.0';
import store from './store';

const hist = createBrowserHistory();

function App(props) {
  const { globalLoading  } = props;
  return (
    <React.Fragment>
      <GlobalLoading loading={globalLoading} />
      
        <Router history={hist}>
          <Switch>
            <Route path='/' component={Admin} />
            <Redirect from='/' to='/calendar' />
          </Switch>
        </Router>
      
    </React.Fragment>
  );
}

const ConnectApp = connect(state => 
   state.globalLoading
 )(App);

function Root(props) {
  const { auth, get_services, get_orders } = props;
  useEffect(() => {
    auth ? get_services() : console.log('no auth');
  }, [get_services, auth]);
  useEffect(() => {
    auth ? get_orders() : console.log('no auth');
  }, [get_orders, auth]);

return  <React.Fragment>{auth?<ConnectApp />: <Login />}</React.Fragment>};


const ConnectRoot = connect(
  state => state.person,
  { ...action.service, ...action.order }
)(Root);

export default function MyApp() {
  return (
    <Provider store={store}>
      <ConnectRoot />
    </Provider>
  );
}
