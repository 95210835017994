import * as TYPES from "../action-type";
import { orderToEvents } from "../../utils/utils";

export default function order(
  state = {
    events: [],
    orders: [],
    searchingOrders: [],
    searching: false
  },
  action
) {
  switch (action.type) {
    case TYPES.GET_EVENTS:
      {
        const { status, data, events } = action.Data.data;
        if (status === 200) {
          state = JSON.parse(JSON.stringify(state));
          state.events = orderToEvents(data, events);
        }
      }
      break;
    case TYPES.CREATE_EVENT:
      state = { ...state, events: [...state.events, action.event] };
      break;
    case TYPES.FINISH_EVENT:
      state = {
        ...state,
        events: state.events.map(i =>
          i.id !== action.id
            ? i
            : { ...i, resource: { ...i.resource, finished: true } }
        )
      };
      break;
    case TYPES.CHANGE_EVENT:
      state = {
        ...state,
        events: state.events.map(i =>
          i.id !== action.event.id ? i : action.event
        )
      };
      break;
    case TYPES.DELETE_EVENT:
      state = {
        ...state,
        events: state.events.filter(i => i.id !== action.id)
      };
      break;
    case TYPES.SET_ORDERS:
      state = {
        ...state,
        orders: action.orders
      };
      break;
    case TYPES.SET_SEARCH_ORDERS:
      state = {
        ...state,
        searchingOrders: action.orders,
        searching: true
      };
      break;
    case TYPES.CANCEL_SEARCHING:
      state = {
        ...state,
        searching: false
      };
      break;
    default:
      break;
  }

  return state;
}
